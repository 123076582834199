import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { Button } from '@geneui/components';

import Loader from 'components/Loader';
import FileUploader from 'components/FileUploader';

import { useTranslator } from 'utils';
import { blobToBase64 } from 'utils/helpers/fileHelper';
import { MAX_FILE_SIZE_IN_MB } from 'constants/defines';
import { useApp } from 'utils/hooks/useApp';
import { useConfig } from 'utils/hooks/useConfig';
import { useLogoutMutation } from 'reduxStore/services/authApi';
import { useGetVerificationDocQuery, useSaveVerificationDocMutation } from 'reduxStore/services/userApi';

const DocumentVerification = ({ className }) => {
  const {
    currentUser: { affiliateId },
  } = useApp();
  const {
    config: { partnerName },
  } = useConfig();
  const [logout] = useLogoutMutation();
  const { data, isFetching: isGetVerificationDocLoading } = useGetVerificationDocQuery({ affiliateId });
  const [saveVerificationDoc, { isLoading: isSaveVerificationDocLoadig }] = useSaveVerificationDocMutation();

  const { t } = useTranslator();
  const history = useHistory();
  const [selectedImage, setSelectedImage] = useState({ path: [] });

  const verificationDoc = data?.result?.[0];
  const isFileUploaded = verificationDoc?.document;

  const onFileUpload = async ({ value }) => {
    if (!value.length) {
      setSelectedImage({ path: [] });
      return;
    }
    const { blob, name, path } = value[0] ?? {};

    try {
      const imageBase64 = await blobToBase64({
        blob,
        name,
        maxSize: MAX_FILE_SIZE_IN_MB,
        t,
      });

      setSelectedImage({
        path: Array(path),
        name,
        imageBase64,
      });
    } catch (error) {
      setSelectedImage({ path: [] });
      console.error(error);
    }
  };

  const onVerify = () => {
    try {
      saveVerificationDoc({
        verificationDocument: {
          affiliateId,
          document: selectedImage.imageBase64,
          filename: selectedImage.name,
        },
      });
    } catch {}
  };

  const onSignOut = () => {
    logout();
    history.push('/');
  };

  return (
    <>
      <div className={classNames('authorisationFormCallToRegistration-bc', className)}>
        <div>
          <h1 className="title">{partnerName}</h1>
          <h2 className="pageName">{t(!isFileUploaded ? 'document-verification' : 'verification-is-in-progress')}</h2>
          <span className="info">
            {`${t(
              !isFileUploaded
                ? 'upload-a-document-file-for-verification'
                : 'please-wait-until-the-admin-give-you-access'
            )}.`}
          </span>
          {!isFileUploaded && (
            <>
              <FileUploader
                className={classNames('uploader', {
                  cloudInvisible: selectedImage.path.length,
                  invalid: selectedImage.path.length === 0,
                })}
                maxFileCount={1}
                images={selectedImage.path}
                uploaderAppearance="cloud"
                isActiveDrop
                uploadedItemsAppearance="box"
                typesList={['png', 'jpg', 'jpeg', 'pdf']}
                onChange={onFileUpload}
              />
              <Button
                size="big"
                flexibility="full-width"
                color="confirm"
                disabled={!selectedImage.path.length}
                onClick={onVerify}
              >
                {t('verify')}
              </Button>
            </>
          )}
        </div>
        <Button className="signOut" size="big" flexibility="full-width" disabled={!selectedImage} onClick={onSignOut}>
          {t('sign-out')}
        </Button>
      </div>
      <Loader isBusy={!affiliateId || isGetVerificationDocLoading || isSaveVerificationDocLoadig} />
    </>
  );
};

export default DocumentVerification;
